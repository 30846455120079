/**
 * @selector [data-js-search-bar]
 * @priority high
 */
import { default as BaseModule } from '../../modules/BaseModule.js';

export default class SearchBar extends BaseModule {
    init(element) {
        this.el = element;

        this.bindListeners();

        return this;
    }

    bindListeners() {
        this.on('click', this.onSearchBarClick);
    }

    onSearchBarClick(e) {
        e.preventDefault();
        e.stopPropagation();
        const searchTrigger = document.querySelector('[data-js-sitenav-refs="searchTrigger"]');
        searchTrigger?.click();
    }
}
