import './loaded-jquery.js';
import popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.min';
import { rIC } from '../lib/util/requestIdleCallback.js';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/native-loading/ls.native-loading';
import 'lazysizes/plugins/custommedia/ls.custommedia';

// low prio plugins (wil get executed once the main-thread isn't busy anymore)
const idleCallback = rIC(() => {
    import('../lib/browser/FlyingFocus.js');
});

import willAppear from './plugins/will-appear.js';

const visibilityObserver = willAppear();
