/**
 * @selector [data-js-highprio]
 * @enabled true
 * @priority high
 */
import BaseModule from '../../modules/BaseModule';

export default class HighPrio extends BaseModule {
    constructor() {
        super();
    }

    init(element) {
        this.el = element;

        console.log('init', this.ns);

        return this;
    }
}
