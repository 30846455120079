/**
 * BaseModule
 */
import EventListener from '../../lib/eventlistener/EventListener.js';
import objectPath from 'object-path';
import fastdom from 'fastdom';

const namespaces = {};

export default class BaseModule {
    static ns(name) {
        if (!namespaces[name]) {
            namespaces[name] = 0;
        }

        return `.${name}${++namespaces[name]}`;
    }

    constructor() {
        this.el = null;

        this.ns = BaseModule.ns(this.constructor.name);
    }

    init(element) {
        this.el = element;

        return this;
    }

    getElement() {
        if (this.el == null) {
            console.warn(
                'Element (`NodeList`) is undefined. You should save the components root node to this.el within the Modules `init()`',
            );
        }

        return this.el;
    }

    domMeasure(cb) {
        const self = this;
        const job = function () {
            self._job().remove('fastdom', job);
            cb.call(self);
        };
        this._job().add('fastdom', job);
        return fastdom.measure(job);
    }

    domMutate(cb) {
        const self = this;
        const job = function () {
            self._job().remove('fastdom', job);
            cb.call(self);
        };
        this._job().add('fastdom', job);
        return fastdom.mutate(job);
    }

    cancelFastdom(task) {
        this._job().remove('fastdom', task);
        return fastdom.clear(task);
    }

    tick(cb) {
        const self = this;
        const tickRequest = window.requestAnimationFrame(() => {
            self._job().remove('tick', tickRequest);
            cb.call(self);
        });
        this._job().add('tick', tickRequest);
        return tickRequest;
    }

    timeout(cb, delay) {
        const self = this;
        const tickRequest = setTimeout(function () {
            self._job().remove('timeout', tickRequest);
            cb.call(self);
        }, delay);
        this._job().add('timeout', tickRequest);
        return tickRequest;
    }

    clearTimeout(tickRequest) {
        this._job().remove('timeout', tickRequest);
        clearTimeout(tickRequest);
    }

    cancelTick(tickRequest) {
        this._job().remove('tick', tickRequest);

        window.cancelAnimationFrame(tickRequest);
    }

    static _getListenerArguments() {
        let event, selector, handler, target;

        let args = Array.prototype.slice.call(arguments); // copy arguments

        switch (args.length) {
            case 2:
                target = this.getElement();
                event = args.shift();
                handler = args.shift();
                break;

            case 3:
                event = args.shift();
                if (typeof event === 'string') {
                    target = this.getElement();
                    selector = args.shift();
                    handler = args.shift();
                } else {
                    target = event;
                    event = args.shift();
                    handler = args.shift();
                }

                break;

            case 4:
                target = args.shift();
                event = args.shift();
                selector = args.shift();
                handler = args.shift();
                break;
        }

        handler = this.bind(handler);

        event = event.replace(/(\s+|$)/g, `${this.ns}$1`);

        if (selector) {
            return [target, event, selector, handler];
        }

        return [target, event, handler];
    }

    // on(event, selector, handler)
    // on(element, event, selector, handler)
    // on(event, handler)
    // on(element, event, handler)
    on() {
        const args = BaseModule._getListenerArguments.apply(this, arguments);

        EventListener.on.apply(this.getElement(), args);
    }

    one() {
        const args = BaseModule._getListenerArguments.apply(this, arguments);

        EventListener.one.apply(this.getElement(), args);
    }

    trigger(...args) {
        args.unshift(this.getElement());

        EventListener.trigger.apply(this.getElement(), args);
    }

    getOptions(attributeName) {
        const camelizedAttributeName = attributeName.replace(/-./g, (x) => x[1].toUpperCase());
        const dataValue = this.el.dataset[camelizedAttributeName];

        if (dataValue === '' || dataValue === attributeName || dataValue == 'true') {
            return {};
        }

        try {
            // if its an object
            return JSON.parse(dataValue);
        } catch (e) {
            console.log(e);

            // if its just a string
            return dataValue;
        }
    }

    _job() {
        if (!this.__jobs) {
            this.__jobs = {
                items: {},
                get: function (name) {
                    if (!this.items[name]) {
                        this.items[name] = [];
                    }
                    return this.items[name];
                },
                add: function (name, job) {
                    this.get(name).push(job);
                },
                remove: function (job) {
                    const items = this.get(name);
                    const index = items.indexOf(job);
                    if (index >= 0) {
                        items.splice(index, 1);
                    }
                },
            };
        }
        return this.__jobs;
    }

    off() {
        EventListener.off(this.ns);

        if (this.nstemp) {
            EventListener.off(this.nstemp);
        }

        // cancel all async jobs
        this._job()
            .get('tick')
            .forEach((job) => {
                AnimationFrame.cancel(job);
            });

        this._job()
            .get('timeout')
            .forEach((job) => {
                clearTimeout(job);
            });

        this._job()
            .get('fastdom')
            .forEach((job) => {
                fastdom.clear(job);
            });

        this.__jobs = null;
    }

    destroy() {
        this.off();

        if (this.el) {
            // remove event listeners
            this.el = undefined;
        }
    }

    bind(method) {
        const self = this;

        return function () {
            method.apply(self, arguments);
        };
    }
}

export const toArray = (val) => (Array.isArray(val) ? val : [val]);

export function getRefs(element, selector) {
    const childs = [
        ...(element.querySelectorAll(`[${selector}-refs]`) || []),
        ...(element.querySelectorAll(`[${selector}-ref]`) || []),
    ];

    const $refs = Array.from(childs).reduce((refs, childElement) => {
        const path = childElement.getAttribute(`${selector}-refs`) || childElement.getAttribute(`${selector}-ref`);

        if (objectPath.has(refs, path)) {
            // convert it to array if it already exists
            objectPath.set(refs, path, toArray(objectPath.get(refs, path)));

            objectPath.push(refs, path, childElement);
        } else {
            objectPath.set(refs, path, childElement);
        }

        return refs;
    }, {});

    return {
        $el: element,
        ...$refs,
    };
}
