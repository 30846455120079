/**
 * @selector [data-js-sitenav]
 * @priority high
 */
import { default as BaseModule, getRefs, toArray } from '../../modules/BaseModule.js';
import { isClickOutside } from '../../../lib/util/isClickOutside.js';

export default class SiteNav extends BaseModule {
    init(element) {
        this.el = element;
        this.$refs = getRefs(this.el, 'data-js-sitenav');

        this.bindListeners();

        return this;
    }

    bindListeners() {
        this.on('click', '[data-js-sitenav-refs="mainLink"]', this.onLinkClick);
        this.on('click', '[data-js-sitenav-refs="searchTrigger"]', this.onSearchTriggerClick);

        this.on(document, 'click', this.onClickOutside);
        this.on(document, 'keydown', this.onKeyDown);

        this.on(document, 'click', '[data-js-sitenav-action="close"]', this.onCloseClick);
    }

    onKeyDown(e) {
        if (e.key === 'Escape') {
            this.closeAll();
        }
    }

    onCloseClick(e) {
        e.preventDefault();

        this.closeAll();
    }

    onSearchTriggerClick(e) {
        e.preventDefault();

        this.closeAll();

        // there is another controller on this element... SiteHeader.js
        // we cannot focus the search input if the header is unpinned
        // we have to pin it first
        if (this.el._module.service && this.el._module.service.state.value === 'unpinned') {
            this.el._module.service.send('PIN');
        }

        document.documentElement.setAttribute('data-js-sitenav-state', 'search');

        let searchField = this.el.querySelector('[data-js-site-search="data-js-site-search"] input[type="search"]');

        // wait for the sticky header transition to finish
        // frontend/stageny/components/global/SiteHeader/SiteHeader.scss:102
        setTimeout(() => {
            searchField.focus();
        }, 201);
    }

    onClickOutside(e) {
        if (isClickOutside(this.el, e.target)) {
            this.closeAll();
        }
    }

    onLinkClick(e) {
        const $target = e.delegateTarget;
        const mainNavEntry = $target.closest('.MainNavEntry');
        const mainNavLinks = mainNavEntry.querySelectorAll('.MainNavSub--link');

        if (mainNavLinks.length > 0) {
            e.preventDefault();
            if ($target.classList.contains('is-open')) {
                this.closeAll();
            } else {
                this.open($target);
            }
        }
    }

    open($link) {
        this.el.style.setProperty('--sitenav-height', $link.getBoundingClientRect().height);

        this.closeAll();

        const $entry = $link.closest('[data-js-sitenav-refs="main"]');

        $entry.classList.add('is-open');
        $link.setAttribute('aria-expanded', 'true');

        document.documentElement.setAttribute('data-js-sitenav-state', 'dropdown');

        this.el.style.setProperty('--sitenav-background-height', this.$refs.body.getBoundingClientRect().height);
    }

    closeAll() {
        toArray(this.$refs.main).forEach(($entry) => {
            if (!$entry) return;
            $entry.classList.remove('is-open');
        });

        toArray(this.$refs.mainLink).forEach(($link) => {
            if (!$link) return;
            $link.setAttribute('aria-expanded', 'false');
        });

        document.documentElement.removeAttribute('data-js-sitenav-state');
    }
}
