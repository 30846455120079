import fastdom from 'fastdom';

const MUTATION_EVENTS = ['click', 'load', 'focus', 'mouseover', 'transitionend', 'animationend', 'webkitAnimationEnd'];

let mutationObserver;
let elements = [];

let stack = {
    maxSize: 12,
    delay: 100,
    items: [],
};

let options = {
    expandFactor: 1,
    scrolledPastVertical: true,
    scrolledPastHorizontal: true,
};

const rIC = (fn) => window.requestIdleCallback(fn, { timeout: 50 });
const rAF = (fn) => window.requestAnimationFrame(fn);

const throttle = (fn) => {
    let rafScheduled;
    let lastTime = 0;

    const run = () => {
        rafScheduled = false;
        lastTime = Date.now();

        fn();
    };

    const afterRAF = () => setTimeout(run);
    const requestRAF = () => rAF(afterRAF);

    return () => {
        if (rafScheduled) {
            return;
        }

        let delay = 120 - (Date.now() - lastTime);

        rafScheduled = true;

        delay = Math.max(9, delay);

        setTimeout(requestRAF, delay);
    };
};

const isIntersecting = (el) => {
    const rect = el.getBoundingClientRect();

    const viewportHeight = (window.innerHeight || document.documentElement.clientHeight) * options.expandFactor;
    const viewportWidth = (window.innerWidth || document.documentElement.clientWidth) * options.expandFactor;

    const vertInView = rect.top <= viewportHeight && (options.scrolledPastVertical || rect.top + rect.height >= 0);
    const horInView = rect.left <= viewportWidth && (options.scrolledPastHorizontal || rect.left + rect.width >= 0);

    return vertInView && horInView;
};

const isProcessed = (element) => element.willAppearProcessed === true;

const markAsProcessed = (element) => (element.willAppearProcessed = true);

const getStack = (el) => {
    // var stackKey = el.getAttribute('data-js-appear') || 'default';

    // if (!stacks[stackKey]) {
    // 	stacks[stackKey] = {
    // 		maxSize: 6,
    // 		delay: 150,
    // 		items: []
    // 	};
    // }

    // return stacks[stackKey];

    return stack;
};

const rAFShowIt = (el) =>
    fastdom.mutate(() => {
        el.classList.remove('will-appear');
        el.classList.add('did-appear');

        el.willAppearProcessed = undefined;
    });

const getStackMaxSize = (stack) => {
    return stack.maxSize === -1 ? stack.items.length : stack.maxSize;
};

const getNextInStack = (stack) => {
    return stack.items.splice(0, Math.max(1, stack.items.length - getStackMaxSize(stack)));
};

const revealNextInStack = (stack, delay) => {
    clearTimeout(stack.timeout);

    if (stack.items.length) {
        stack.timeout = setTimeout(() => {
            const next = getNextInStack(stack);

            for (let i = 0, len = next.length; i < len; i++) {
                rAFShowIt(next[i]);
            }

            return revealNextInStack(stack, stack.delay);
        }, delay || 0);
    }
};

const scheduledRevealStack = (stack) => {
    setInterval(() => {
        const next = getNextInStack(stack);

        for (let i = 0, len = next.length; i < len; i++) {
            rAFShowIt(next[i]);
        }
    }, stack.delay);
};

const validateElements = () => {
    for (let i = 0; i < elements.length; i++) {
        const el = elements[i];

        if (!isProcessed(el) && isIntersecting(el)) {
            var stack = getStack(el);

            stack.items.push(el);

            markAsProcessed(el);
            revealNextInStack(stack);
        }
    }
};

const throttledValidateElements = throttle(validateElements);

const init = (className = 'will-appear', options) => {
    stack = Object.assign(stack, options);

    elements = document.getElementsByClassName(className);

    document.addEventListener('scroll', throttledValidateElements, true);
    document.addEventListener('resize', throttledValidateElements, true);

    MUTATION_EVENTS.forEach((type) => document.addEventListener(type, throttledValidateElements, true));

    mutationObserver = new MutationObserver(throttledValidateElements);
    mutationObserver.observe(document.documentElement, {
        childList: true,
        subtree: true,
        attributes: true,
    });

    throttledValidateElements();
};

const destroy = () => {
    elements = undefined;

    document.removeEventListener('scroll', throttledValidateElements, true);
    document.removeEventListener('resize', throttledValidateElements, true);

    MUTATION_EVENTS.forEach((type) => document.removeEventListener(type, throttledValidateElements, true));

    if (mutationObserver) {
        mutationObserver.disconnect();
        mutationObserver = undefined;
    }
};

export default function (className = 'will-appear', options) {
    init(className, options);

    return {
        destroy,
    };
}
