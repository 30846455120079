import contextTrigger from '../lib/util/contextTrigger.js';
import ModuleManager from '../lib/util/ModuleManager.js';

import HighPrio from './modules/global/HighPrio.js';
import SiteNav from './modules/global/SiteNav.js';
import SearchBar from './modules/widgets/SearchBar.js';

export default (function () {
    let time = new Date();

    contextTrigger.add('[data-js-checkboxCard]', function () {
        var elem = this;
        import('./modules/apps/CheckboxCard.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-contact-wizard]', function () {
        var elem = this;
        import('./modules/apps/ContactWizard.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-onboarding]', function () {
        var elem = this;
        import('./modules/apps/Onboarding.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-onboarding-form]', function () {
        var elem = this;
        import('./modules/apps/OnboardingForm.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-fund-saving-calculator]', function () {
        var elem = this;
        import('./modules/apps/calculators/FundSavingCalculator.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-FundSavingCalculatorChart', function () {
        var elem = this;
        import('./modules/apps/calculators/FundSavingCalculatorChart.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-FundSavingCalculatorTabs', function () {
        var elem = this;
        import('./modules/apps/calculators/FundSavingCalculatorTabs.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-fund-saving-form-validation]', function () {
        var elem = this;
        import('./modules/apps/calculators/FundSavingFormValidation.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-Accordion', function () {
        var elem = this;
        import('./modules/components/Accordion.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-backgroundvideo]', function () {
        var elem = this;
        import('./modules/components/BackgroundVideo.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-bar-chart-horizontal]', function () {
        var elem = this;
        import('./modules/components/BarChartHorizontal.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-Chatbot', function () {
        var elem = this;
        import('./modules/components/Chatbot.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-conditionalFields]', function () {
        var elem = this;
        import('./modules/components/ConditionalFields.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-country-confirmation="form"]', function () {
        var elem = this;
        import('./modules/components/CountryConfirmation.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-EbankingNotifications', function () {
        var elem = this;
        import('./modules/components/EbankingNotifications.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-EbankingSupportMenu', function () {
        var elem = this;
        import('./modules/components/EbankingSupportMenu.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-feedback]', function () {
        var elem = this;
        import('./modules/components/FeedbackButton.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-fileupload]', function () {
        var elem = this;
        import('./modules/components/FileUpload.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-fittext]', function () {
        var elem = this;
        import('./modules/components/FitText.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-hero-campaign]', function () {
        var elem = this;
        import('./modules/components/HeroCampaign.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-iframe]', function () {
        var elem = this;
        import('./modules/components/Iframe.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('input[type="date"]', function () {
        var elem = this;
        import('./modules/components/InputDate.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-MagnifyPicture', function () {
        var elem = this;
        import('./modules/components/MagnifyPicture.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-portrait-grid]', function () {
        var elem = this;
        import('./modules/components/PortraitGrid.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.richtextWrapper', function () {
        var elem = this;
        import('./modules/components/RichtextWrapper.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-stepslider]', function () {
        var elem = this;
        import('./modules/components/StepSlider.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-SupportWizard', function () {
        var elem = this;
        import('./modules/components/SupportWizard.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-TimeWindowComponent', function () {
        var elem = this;
        import('./modules/components/TimeWindowComponent.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-tooltip]', function () {
        var elem = this;
        import('./modules/components/Tooltip.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-pagegrid]', function () {
        var elem = this;
        import('./modules/dev/PageGrid.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.DevPageGrid', function () {
        var elem = this;
        import('./modules/dev/ThemeSwitcher.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-form-validation]', function () {
        var elem = this;
        import('./modules/form/FormValidation.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-input-watcher]', function () {
        var elem = this;
        import('./modules/form/InputWatcher.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-input-with-reset]', function () {
        var elem = this;
        import('./modules/form/InputWithReset.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-MultistepHeader', function () {
        var elem = this;
        import('./modules/form/MultistepHeader.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-Alert', function () {
        var elem = this;
        import('./modules/global/Alert.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-ConversionBar', function () {
        var elem = this;
        import('./modules/global/ConversionBar.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('#onetrust-consent-sdk', function () {
        var elem = this;
        import('./modules/global/CookieBanner.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-copy-to-clipboard]', function () {
        var elem = this;
        import('./modules/global/CopyToClipboard.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-dynatrace]', function () {
        var elem = this;
        import('./modules/global/Dynatrace.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-floating]', function () {
        var elem = this;
        import('./modules/global/Floating.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-floating-image]', function () {
        var elem = this;
        import('./modules/global/FloatingImage.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });
    contextTrigger.add('[data-js-highprio]', function () {
        var elem = this;

        (function (Module) {
            ModuleManager.connect(Module, elem);
        })(HighPrio);
    });

    contextTrigger.add('.js-HomeSlider', function () {
        var elem = this;
        import('./modules/global/HomeSlider.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-LinkTrigger', function () {
        var elem = this;
        import('./modules/global/LinkTrigger.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-mobilenav]', function () {
        var elem = this;
        import('./modules/global/MobileNav.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-ModuleWithGlobalDependency', function () {
        var elem = this;
        import('./modules/global/ModuleWithGlobalDependency.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.js-ScrollSlider', function () {
        var elem = this;
        import('./modules/global/ScrollSlider.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-scroll-style]', function () {
        var elem = this;
        import('./modules/global/ScrollStyle.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-search-input]', function () {
        var elem = this;
        import('./modules/global/SearchInput.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-servicemodal]', function () {
        var elem = this;
        import('./modules/global/ServiceModal.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-sitefooter]:not([data-variant="home"])', function () {
        var elem = this;
        import('./modules/global/SiteFooter.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-siteheader]', function () {
        var elem = this;
        import('./modules/global/SiteHeader.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });
    contextTrigger.add('[data-js-sitenav]', function () {
        var elem = this;

        (function (Module) {
            ModuleManager.connect(Module, elem);
        })(SiteNav);
    });

    contextTrigger.add('[data-js-site-search]', function () {
        var elem = this;
        import('./modules/global/SiteSearch.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-switch]', function () {
        var elem = this;
        import('./modules/global/Switch.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-tracking]', function () {
        var elem = this;
        import('./modules/global/Tracking.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-ajax-form]', function () {
        var elem = this;
        import('./modules/widgets/AjaxForm.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-autocomplete]', function () {
        var elem = this;
        import('./modules/widgets/Autocomplete.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[href^="tel:"]', function () {
        var elem = this;
        import('./modules/widgets/ENLDirectCall.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-element-dimensions]', function () {
        var elem = this;
        import('./modules/widgets/ElementDimensions.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-widget="hidden-content"]', function () {
        var elem = this;
        import('./modules/widgets/HiddenContent.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-hidden-text]', function () {
        var elem = this;
        import('./modules/widgets/HiddenText.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-hovergroup]', function () {
        var elem = this;
        import('./modules/widgets/HoverGroup.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-loadmore]', function () {
        var elem = this;
        import('./modules/widgets/LoadMore.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('.modal', function () {
        var elem = this;
        import('./modules/widgets/Modal.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('body', function () {
        var elem = this;
        import('./modules/widgets/MousePosition.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-print]', function () {
        var elem = this;
        import('./modules/widgets/PrintLink.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });
    contextTrigger.add('[data-js-search-bar]', function () {
        var elem = this;

        (function (Module) {
            ModuleManager.connect(Module, elem);
        })(SearchBar);
    });

    contextTrigger.add('.js-Slider', function () {
        var elem = this;
        import('./modules/widgets/Slider.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-video-controls]', function () {
        var elem = this;
        import('./modules/widgets/VideoWithControls.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.add('[data-js-video-progress]', function () {
        var elem = this;
        import('./modules/widgets/VideoWithProgress.js').then(({ default: Module }) => {
            ModuleManager.connect(Module, elem);
        });
    });

    contextTrigger.validate(document.body);

    //console.log("Selecting components took: ", new Date() - time, 'ms');
})();
